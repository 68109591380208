// src/utils/walletAdapter.ts
import { WalletContextState } from '@solana/wallet-adapter-react';
import { PublicKey, Transaction, Keypair } from '@solana/web3.js';
import { Wallet } from '@project-serum/anchor';

export class AnchorWalletAdapter implements Wallet {
  readonly payer: Keypair;

  constructor(private walletContext: WalletContextState) {
    if (!walletContext.publicKey || !walletContext.signTransaction) {
      throw new Error("Wallet not properly connected");
    }
  
    this.payer = Keypair.generate();
  }

  get publicKey(): PublicKey {
    if (!this.walletContext.publicKey) {
      throw new Error("Wallet not connected");
    }
    return this.walletContext.publicKey;
  }

  async signTransaction(tx: Transaction): Promise<Transaction> {
    if (!this.walletContext.signTransaction) {
      throw new Error("Wallet does not support transaction signing");
    }
    return await this.walletContext.signTransaction(tx);
  }

  async signAllTransactions(txs: Transaction[]): Promise<Transaction[]> {
    if (!this.walletContext.signAllTransactions) {
      return Promise.all(txs.map(tx => this.signTransaction(tx)));
    }
    return await this.walletContext.signAllTransactions(txs);
  }
}