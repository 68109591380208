import { Connection, PublicKey, SystemProgram } from '@solana/web3.js';
import * as anchor from '@project-serum/anchor';
import { Wallet, Program, Idl, AnchorProvider } from '@project-serum/anchor';
import { 
    TOKEN_PROGRAM_ID, 
    getAssociatedTokenAddress,
    createAssociatedTokenAccountInstruction,
    getAccount
} from '@solana/spl-token';
import { BN } from '@project-serum/anchor';


interface PsomanIDL extends Idl {
    name: "psoman_vault";
    instructions: {
        name: string;
        accounts: {
            name: string;
            isMut: boolean;
            isSigner: boolean;
        }[];
        args: {
            name: string;
            type: "u64"; 
        }[];
    }[];
}

export const PROGRAM_ID = new PublicKey("CHZwR6dGZvFXxW8ZeccGzwcrxYJENRP1Sscaw9KKfU3x");
export const PSOMAN_MINT = new PublicKey("2nGKEZezJro5JHhduPCRTtLnqsqs9QjDEAtDRQBsptsA");
export const VAULT_PDA = new PublicKey("57w82g8PdLA58gLuXYKpmv5mtwr11gD519jXq3kAuMqG");
export const VAULT_SOL_PDA = new PublicKey("78mfarebF6PpDjbyhDFvNAhCoWQKaRgzA4mGpNMxsLBC");
export const MIN_SOL_AMOUNT = 0.01; 
export const MIN_BURN_AMOUNT = 10_000; 

export const IDL: PsomanIDL = {
    "version": "0.1.0",
    "name": "psoman_vault",
    "instructions": [
        {
            "name": "mintPsoman",
            "accounts": [
                { "name": "user", "isMut": true, "isSigner": true },
                { "name": "vault", "isMut": true, "isSigner": false },
                { "name": "mint", "isMut": true, "isSigner": false },
                { "name": "vaultSol", "isMut": true, "isSigner": false },
                { "name": "userToken", "isMut": true, "isSigner": false },
                { "name": "tokenProgram", "isMut": false, "isSigner": false },
                { "name": "systemProgram", "isMut": false, "isSigner": false }
            ],
            "args": [
                { "name": "solAmount", "type": "u64" }
            ]
        },
        {
            "name": "burnPsoman",
            "accounts": [
                { "name": "user", "isMut": true, "isSigner": true },
                { "name": "vault", "isMut": true, "isSigner": false },
                { "name": "mint", "isMut": true, "isSigner": false },
                { "name": "vaultSol", "isMut": true, "isSigner": false },
                { "name": "userToken", "isMut": true, "isSigner": false },
                { "name": "tokenProgram", "isMut": false, "isSigner": false },
                { "name": "systemProgram", "isMut": false, "isSigner": false }
            ],
            "args": [
                { "name": "psomanAmount", "type": "u64" }
            ]
        }
    ]
} as const;

export class PsomanContract {
    program: Program<PsomanIDL>;
    wallet: Wallet;
    connection: Connection;
    vault: PublicKey;
    vaultSol: PublicKey;
    provider: AnchorProvider;

    constructor(wallet: Wallet, connection: Connection) {
        this.wallet = wallet;
        this.connection = connection;

        this.provider = new anchor.AnchorProvider(
            connection,
            wallet,
            { commitment: "confirmed" }
        );

        this.program = new Program<PsomanIDL>(IDL, PROGRAM_ID, this.provider);
        this.vault = VAULT_PDA;
        this.vaultSol = VAULT_SOL_PDA;
    }

    async userMint(solAmount: number): Promise<string> {
        try {
            if (solAmount < MIN_SOL_AMOUNT) {
                throw new Error(`Minimum mint amount is ${MIN_SOL_AMOUNT} SOL`);
            }

            const walletPubkey = this.wallet.publicKey;
            if (!walletPubkey) {
                throw new Error("Wallet not connected");
            }

            const userAta = await getAssociatedTokenAddress(
                PSOMAN_MINT,
                walletPubkey
            );

            const transaction = new anchor.web3.Transaction();

            try {
                await getAccount(this.connection, userAta);
            } catch (error) {
                const createAtaIx = createAssociatedTokenAccountInstruction(
                    walletPubkey,
                    userAta,
                    walletPubkey,
                    PSOMAN_MINT
                );
                transaction.add(createAtaIx);
            }

            const lamports = solAmount * anchor.web3.LAMPORTS_PER_SOL;
            const mintIx = await this.program.methods
                .mintPsoman(new BN(lamports))
                .accounts({
                    user: walletPubkey,
                    vault: this.vault,
                    mint: PSOMAN_MINT,
                    vaultSol: this.vaultSol,
                    userToken: userAta,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    systemProgram: SystemProgram.programId,
                })
                .instruction();

            transaction.add(mintIx);

            const tx = await this.provider.sendAndConfirm(transaction);
            await this.connection.confirmTransaction(tx, 'confirmed');
            return tx;
        } catch (error) {
            console.error("Mint error:", error);
            throw this.handleError(error);
        }
    }

    async burnPsoman(psomanAmount: number): Promise<string> {
        try {
            if (psomanAmount < MIN_BURN_AMOUNT) {
                throw new Error(`Minimum burn amount is ${MIN_BURN_AMOUNT} PSOMAN`);
            }

            const walletPubkey = this.wallet.publicKey;
            if (!walletPubkey) {
                throw new Error("Wallet not connected");
            }

            const userAta = await getAssociatedTokenAddress(
                PSOMAN_MINT,
                walletPubkey
            );

            const tokenAccount = await getAccount(this.connection, userAta);
            const balance = Number(tokenAccount.amount);
            const burnAmount = psomanAmount * 1_000_000;
            if (balance < burnAmount) {
                throw new Error("Insufficient PSOMAN balance");
            }

            const tx = await this.program.methods
                .burnPsoman(new BN(burnAmount))
                .accounts({
                    user: walletPubkey,
                    vault: this.vault,
                    mint: PSOMAN_MINT,
                    vaultSol: this.vaultSol,
                    userToken: userAta,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    systemProgram: SystemProgram.programId,
                })
                .rpc();

            await this.connection.confirmTransaction(tx, 'confirmed');
            return tx;
        } catch (error) {
            console.error("Burn error:", error);
            throw this.handleError(error);
        }
    }

    private handleError(error: any): Error {
        if (error.message) {
            if (error.message.includes("0x1")) {
                return new Error("Insufficient SOL balance");
            }
            if (error.message.includes("0x2")) {
                return new Error("Insufficient PSOMAN balance");
            }
            return new Error(error.message);
        }
        return new Error("Transaction failed");
    }
}