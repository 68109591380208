// src/services/tokenDescriptions.ts

interface TokenDescription {
    title: string;
    description: string;
}

export const TOKEN_DESCRIPTIONS: { [key: string]: TokenDescription } = {
    "GXBQgQijLFgfNgHazpr7KbwK2W7A8zdSTWk3xQCymq56": {
        title: "About PSOMAN",
        description: `PSOMAN is a community-driven meme token on Solana that aims to create a fun and engaging ecosystem. 
        
        Key Features:
        • Community-focused governance
        • Regular token burns
        • Innovative staking rewards
        • Active development team
        
        Join our vibrant community and be part of the PSOMAN revolution!`
    },
    "2qEHjDLDLbuBgRYvsxhc5D6uDWAivNFZGan56P1tpump": {
        title: "About PUNT",
        description: `PUNT is a revolutionary meme token designed specifically for the Solana ecosystem. 
        
        Our Mission:
        • Create a sustainable tokenomics model
        • Build strong community partnerships
        • Develop unique DeFi solutions
        • Support charitable initiatives
        
        PUNT represents the future of decentralized meme tokens.`
    },
    "CzLSujWBLFsSjncfkh59rUFqvafWcY5tzedWJSuypump": {
        title: "About GOTA",
        description: `GOTA is an innovative token that combines meme culture with real utility. 
        
        Features:
        • Deflationary mechanics
        • Community governance
        • NFT integration
        • Cross-chain compatibility
        
        Join GOTA in revolutionizing the Solana ecosystem!`
    },
    "GN78Djb7J2xc2ZLZaJLoGoF8FU6x7DnK3kUJFsnDpump": {
        title: "About SHRUB",
        description: `SHRUB is a unique token focusing on environmental sustainability in the crypto space.
        
        Our Goals:
        • Carbon-neutral transactions
        • Eco-friendly initiatives
        • Community-driven projects
        • Sustainable growth
        
        Be part of the green revolution with SHRUB!`
    },
    "7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr": {
        title: "About POPCAT",
        description: `POPCAT combines internet culture with blockchain technology.
        
        Highlights:
        • Viral marketing integration
        • Meme-based governance
        • Social media rewards
        • Community events
        
        Experience the future of social tokens with POPCAT!`
    },
    "CBdCxKo9QavR9hfShgpEBG3zekorAeD7W1jfq2o3pump": {
        title: "About LUCE",
        description: `LUCE is a next-generation token focusing on DeFi innovation.
        
        Key Aspects:
        • Advanced yield farming
        • Liquidity mining
        • Cross-chain bridges
        • Governance rights
        
        Join LUCE in shaping the future of DeFi!`
    }
};