import { useState, useEffect } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import { TOKEN_DESCRIPTIONS } from '../services/tokenDescriptions';
import {
  getTokenPriceWithCache,
  formatPrice,
  formatChange,
  getTokenHoldersWithCache,
  formatHolders,
} from '../services/tokenPrice';
import { getTokenInfo } from '../services/tokenService';
import { PsomanContract } from '../services/PsomanContract';
import { AnchorWalletAdapter } from '../utils/walletAdapter';
import 'react-toastify/dist/ReactToastify.css';

// Import images
import Psoman from '../assets/psoman.png';
import PuntsIcon from '../assets/Punts.png';
import GotaIcon from '../assets/gota.png';
import ShrubIcon from '../assets/shrub.png';
import PopcatIcon from '../assets/popcat.png';
import LuceIcon from '../assets/Luce.png';

// Constants
const CACHE_DURATION = 10 * 60 * 1000;
const MIN_SOL_AMOUNT = 0.01;
const MIN_BURN_AMOUNT = 10_000;

const MINT_START_TIME = Date.UTC(2024, 10, 16, 12, 0, 0, 0);
const MINT_DURATION = 48 * 60 * 60 * 1000;

interface TokenInfo {
  holders: number;
  totalSupply: number;
  circulatingSupply: number;
}

interface TokenData {
  name: string;
  icon: string;
  totalSupply: string;
  circulatingSupply: string;
  holders: string;
  price: string;
  marketCap: string;
  creator: string;
  description: string;
  tokenAddress: string;
  isMintEnded: boolean;
  maxSupply: string;
  currentSupply: string;
}

interface TokensDatabase {
  [key: string]: TokenData;
}
const tokensDatabase: TokensDatabase = {
  "GXBQgQijLFgfNgHazpr7KbwK2W7A8zdSTWk3xQCymq56": {
    name: "PSOMAN",
    icon: Psoman,
    totalSupply: "1,000,000,000",
    circulatingSupply: "750,000,000",
    holders: "1,234",
    price: "$5.3315k",
    marketCap: "$2.3M",
    creator: "SOMAN",
    description: "PSOMAN is a community-driven meme token on Solana...",
    tokenAddress: "FLARErwkPxkbyCVrLwk2HGxhTFwtYgVcTGQxnVx7D8Kz",
    isMintEnded: false,
    maxSupply: "1,000,000,000",
    currentSupply: "750,000,000",
  },
  "2qEHjDLDLbuBgRYvsxhc5D6uDWAivNFZGan56P1tpump": {
    name: "PUNT",
    icon: PuntsIcon,
    totalSupply: "999,858,795.88",
    circulatingSupply: "999,858,795.88",
    holders: "28,172",
    price: "$319.0005",
    marketCap: "$1.5M",
    creator: "EMhsQ",
    description: "PUNT token is designed for the Solana ecosystem...",
    tokenAddress: "2qEHjDLDLbuBgRYvsxhc5D6uDWAivNFZGan56P1tpump",
    isMintEnded: true,
    maxSupply: "999,858,795.88",
    currentSupply: "999,858,795.88",
  },
  "CzLSujWBLFsSjncfkh59rUFqvafWcY5tzedWJSuypump": {
    name: "GOTA",
    icon: GotaIcon,
    totalSupply: "999,998,266.59",
    circulatingSupply: "999,998,266.59",
    holders: "28,172",
    price: "$319.0005",
    marketCap: "$1.5M",
    creator: "EMhsQ",
    description: "GOTA token is designed for the Solana ecosystem...",
    tokenAddress: "CzLSujWBLFsSjncfkh59rUFqvafWcY5tzedWJSuypump",
    isMintEnded: true,
    maxSupply: "999,998,266.59",
    currentSupply: "999,998,266.59",
  },
  "GN78Djb7J2xc2ZLZaJLoGoF8FU6x7DnK3kUJFsnDpump": {
    name: "SHRUB",
    icon: ShrubIcon,
    totalSupply: "999,998,266.59",
    circulatingSupply: "999,998,266.59",
    holders: "28,172",
    price: "$319.0005",
    marketCap: "$1.5M",
    creator: "EMhsQ",
    description: "SHRUB token is designed for the Solana ecosystem...",
    tokenAddress: "GN78Djb7J2xc2ZLZaJLoGoF8FU6x7DnK3kUJFsnDpump",
    isMintEnded: true,
    maxSupply: "999,998,266.59",
    currentSupply: "999,998,266.59",
  },
  "7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr": {
    name: "POPCAT",
    icon: PopcatIcon,
    totalSupply: "979,943,720.55",
    circulatingSupply: "979,943,720.55",
    holders: "28,172",
    price: "$319.0005",
    marketCap: "$1.5M",
    creator: "EMhsQ",
    description: "POPCAT token is designed for the Solana ecosystem...",
    tokenAddress: "7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr",
    isMintEnded: true,
    maxSupply: "979,943,720.55",
    currentSupply: "979,943,720.55",
  },
  "CBdCxKo9QavR9hfShgpEBG3zekorAeD7W1jfq2o3pump": {
    name: "LUCE",
    icon: LuceIcon,
    totalSupply: "979,943,720.55",
    circulatingSupply: "979,943,720.55",
    holders: "28,172",
    price: "$319.0005",
    marketCap: "$1.5M",
    creator: "EMhsQ",
    description: "LUCE token is designed for the Solana ecosystem...",
    tokenAddress: "CBdCxKo9QavR9hfShgpEBG3zekorAeD7W1jfq2o3pump",
    isMintEnded: true,
    maxSupply: "979,943,720.55",
    currentSupply: "979,943,720.55",
  },
};
const TokenDetail = () => {
  const { tokenAddress } = useParams<{ tokenAddress: string }>();
  const navigate = useNavigate();
  const token = tokenAddress ? tokensDatabase[tokenAddress] : null;
  const tokenDescription = tokenAddress ? TOKEN_DESCRIPTIONS[tokenAddress] : null;

  const { connection } = useConnection();
  const walletContext = useWallet();

  // State management
  const [transactionStatus, setTransactionStatus] = useState<'idle' | 'processing' | 'success' | 'error'>('idle');
  const [lastTxSignature, setLastTxSignature] = useState('');
  const [mintAmount, setMintAmount] = useState('');
  const [burnAmount, setBurnAmount] = useState('');

  const [tokenPrice, setTokenPrice] = useState(token?.price || '$0.00');
  const [change24h, setChange24h] = useState('0%');
  const [marketCap, setMarketCap] = useState(token?.marketCap || '$0');
  const [mintProgress, setMintProgress] = useState(0);
  
  // @ts-ignore
  const [isMintCompleted, setIsMintCompleted] = useState(false);
  // @ts-ignore
  const [currentSupply, setCurrentSupply] = useState(token?.currentSupply || '0');
  // @ts-ignore
  const [maxSupply, setMaxSupply] = useState(token?.maxSupply || '0');
  // @ts-ignore
  const [realHolders, setRealHolders] = useState(token?.holders || '0');
  
  const [isProcessing, setIsProcessing] = useState(false);
  const [timeLeft, setTimeLeft] = useState<string>('');
  const [tokenInfo, setTokenInfo] = useState<TokenInfo>({
    holders: parseInt(token?.holders.replace(/,/g, '') || '0'),
    totalSupply: parseInt(token?.totalSupply.replace(/,/g, '') || '0'),
    circulatingSupply: parseInt(token?.circulatingSupply.replace(/,/g, '') || '0')
  });

  const calculateMintProgress = () => {
    const now = Date.now();
    
    if (now < MINT_START_TIME) {
      return 0;
    }
    
    if (now > MINT_START_TIME + MINT_DURATION) {
      return 100;
    }
    
    const totalDuration = MINT_DURATION;
    const elapsed = now - MINT_START_TIME;
    const progress = (elapsed / totalDuration) * 100;
    
    return Math.min(Math.max(progress, 0), 100);
  };

  const calculateTimeLeft = () => {
    const now = Date.now();
    
    if (now < MINT_START_TIME) {
      const diff = MINT_START_TIME - now;
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      setTimeLeft(`Mint starts in ${hours}h ${minutes}m`);
      return;
    }
    
    const endTime = MINT_START_TIME + MINT_DURATION;
    const diff = endTime - now;
    
    if (diff <= 0) {
      setTimeLeft('Mint Ended');
      return;
    }
    
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    setTimeLeft(`${hours}h ${minutes}m left`);
  };
    // Functions for data fetching and updates
    const fetchTokenInfo = async () => {
      if (!token?.tokenAddress) return;
  
      try {
        const info = await getTokenInfo(token.tokenAddress);
        setTokenInfo(prev => ({
          ...prev,
          ...info
        }));
  
        setCurrentSupply(info.circulatingSupply.toString());
        setMaxSupply(info.totalSupply.toString());
  
        const progress = (info.circulatingSupply / info.totalSupply) * 100;
        setMintProgress(Math.min(progress, 100));
        setIsMintCompleted(progress >= 100);
      } catch (error) {
        console.error('Error fetching token info:', error);
      }
    };
  
    const fetchTokenSupply = async () => {
      if (token) {
        try {
          const supply = parseFloat(token.circulatingSupply.replace(/,/g, ''));
          const max = parseFloat(token.totalSupply.replace(/,/g, ''));
  
          setCurrentSupply(supply.toString());
          setMaxSupply(max.toString());
  
          const progress = (supply / max) * 100;
          setMintProgress(Math.min(progress, 100));
          setIsMintCompleted(progress >= 100);
        } catch (error) {
          console.error('Error fetching token supply:', error);
        }
      }
    };
  
    const formatProgress = () => {
      if (!token) return '';
      if (token.isMintEnded) {
        return 'Mint Ended';
      }
      
      const now = Date.now();
      if (now < MINT_START_TIME) {
        return timeLeft || 'Starting soon...';
      }
      
      if (now > MINT_START_TIME + MINT_DURATION) {
        return 'Mint Ended';
      }
      
      return timeLeft || 'In progress...';
    };
  
    const fetchHoldersCount = async () => {
      if (!token?.tokenAddress) return;
  
      try {
        const holdersData = await getTokenHoldersWithCache(token.tokenAddress);
        
        if (holdersData.holder > 0) {
          setRealHolders(formatHolders(holdersData.holder));
          setTokenInfo(prev => ({
            ...prev,
            holders: holdersData.holder
          }));
        }
      } catch (error) {
        console.error('Error fetching holders count:', error);
      }
    };
  
    const handleMint = async () => {
      if (!walletContext.connected || !walletContext.publicKey) {
        toast.error("Please connect your wallet first");
        return;
      }
  
      try {
        setTransactionStatus('processing');
        const anchorWallet = new AnchorWalletAdapter(walletContext);
        const contract = new PsomanContract(anchorWallet, connection);
  
        const amount = parseFloat(mintAmount);
        if (isNaN(amount) || amount < MIN_SOL_AMOUNT) {
          throw new Error(`Minimum mint amount is ${MIN_SOL_AMOUNT} SOL`);
        }
  
        const tx = await contract.userMint(amount);
        setLastTxSignature(tx);
        setTransactionStatus('success');
        console.log("Mint transaction:", tx);
        await fetchTokenSupply();
        setMintAmount('');
  
        toast.success("Successfully minted tokens!");
      } catch (error: any) {
        console.error("Mint failed:", error);
        setTransactionStatus('error');
        toast.error(error.message || "Failed to mint tokens");
      } finally {
        setTimeout(() => setTransactionStatus('idle'), 5000);
      }
    };
    const handleBurn = async () => {
      if (!walletContext.connected || !walletContext.publicKey) {
        toast.error("Please connect your wallet first");
        return;
      }
  
      try {
        setTransactionStatus('processing');
        const anchorWallet = new AnchorWalletAdapter(walletContext);
        const contract = new PsomanContract(anchorWallet, connection);
  
        const amount = parseFloat(burnAmount);
        if (isNaN(amount) || amount < MIN_BURN_AMOUNT) {
          throw new Error(`Minimum burn amount is ${MIN_BURN_AMOUNT} tokens`);
        }
  
        const tx = await contract.burnPsoman(amount);
        setLastTxSignature(tx);
        setTransactionStatus('success');
        console.log("Burn transaction:", tx);
  
        toast.success("Successfully burned tokens!");
        setBurnAmount('');
        await fetchTokenSupply();
      } catch (error: any) {
        console.error("Burn failed:", error);
        setTransactionStatus('error');
        toast.error(error.message || "Failed to burn tokens");
      } finally {
        setTimeout(() => setTransactionStatus('idle'), 5000);
        setIsProcessing(false);
      }
    };
  
    const renderTransactionStatus = () => {
      switch (transactionStatus) {
        case 'processing':
          return (
            <div className="transaction-status processing">
              <span className="spinner"></span>
              Processing transaction...
            </div>
          );
        case 'success':
          return (
            <div className="transaction-status success">
              Transaction successful!
              {lastTxSignature && (
                <a
                  href={`https://solscan.io/tx/${lastTxSignature}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Solscan
                </a>
              )}
            </div>
          );
        case 'error':
          return (
            <div className="transaction-status error">
              Transaction failed. Please try again.
            </div>
          );
        default:
          return null;
      }
    };
  
    // Effects
    useEffect(() => {
      if (token) {
        fetchTokenInfo();
        const interval = setInterval(fetchTokenInfo, 300000);
        return () => clearInterval(interval);
      }
    }, [token, tokenAddress]);
  
    useEffect(() => {
      if (token) {
        fetchTokenSupply();
        const supplyInterval = setInterval(fetchTokenSupply, 30000);
        return () => clearInterval(supplyInterval);
      }
    }, [token, tokenAddress]);
  
    useEffect(() => {
      const fetchTokenPrice = async () => {
        if (token) {
          try {
            const priceData = await getTokenPriceWithCache(token.tokenAddress);
            if (priceData) {
              setTokenPrice(formatPrice(priceData.value));
              setChange24h(formatChange(priceData.change24h));
  
              const circulatingSupply = parseFloat(token.circulatingSupply.replace(/,/g, ''));
              const calculatedMarketCap = priceData.value * circulatingSupply;
              setMarketCap(formatPrice(calculatedMarketCap));
            }
          } catch (err) {
            console.error('Error fetching token price:', err);
          }
        }
      };
  
      fetchTokenPrice();
      const interval = setInterval(fetchTokenPrice, 300000); 
      return () => clearInterval(interval);
    }, [token]);
    useEffect(() => {
      if (token) {
        fetchHoldersCount();
        const interval = setInterval(fetchHoldersCount, CACHE_DURATION);
        return () => clearInterval(interval);
      }
    }, [token, tokenAddress]);
  
    useEffect(() => {
      if (!tokenAddress || !token) {
        navigate('/');
      }
    }, [tokenAddress, token, navigate]);
  
    useEffect(() => {
      if (!token?.isMintEnded) {
        const updateProgress = () => {
          setMintProgress(calculateMintProgress());
          calculateTimeLeft();
        };
        
        updateProgress();
        const timer = setInterval(updateProgress, 1000);
        return () => clearInterval(timer);
      } else {
        setMintProgress(100);
      }
    }, [token]);
  
    if (!token) {
      return null;
    }
  
    return (
      <div className="token-detail-page">
        <Navbar />
        <motion.div
          className="token-detail-container"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="token-info-card">
            <div className="token-header">
              <img src={token.icon} alt={token.name} className="token-large-icon" />
              <div className="token-title-info">
                <h1>{token.name}</h1>
                <p className="token-creator">Created by {token.creator}</p>
              </div>
            </div>
  
            <div className="token-stats">
              <div className="stat-item">
                <span className="stat-label">Price</span>
                <span className="stat-value price">{tokenPrice}</span>
              </div>
  
              <div className="stat-item">
                <span className="stat-label">Market Cap</span>
                <span className="stat-value">{marketCap}</span>
              </div>
  
              <div className="stat-item">
                <span className="stat-label">24h Change</span>
                <span className={`stat-value ${change24h.includes('+') ? 'change-positive' : 'change-negative'}`}>
                  {change24h}
                </span>
              </div>
  
              <div className="stat-item">
                <span className="stat-label">Holders</span>
                <span className="stat-value">{tokenInfo.holders.toLocaleString()}</span>
              </div>
  
              <div className="stat-item">
                <span className="stat-label">Total Supply</span>
                <span className="stat-value">{tokenInfo.totalSupply.toLocaleString()}</span>
              </div>
  
              <div className="stat-item">
                <span className="stat-label">Circulating</span>
                <span className="stat-value">{tokenInfo.circulatingSupply.toLocaleString()}</span>
              </div>
            </div>
          </div>
  
          {renderTransactionStatus()}
          <div className="operations-card">
          <div className="operation-section mint-section">
            <h3>Mint {token.name}</h3>
            <div className={`mint-progress ${token.isMintEnded ? 'completed' : ''}`}>
              <div
                className="progress-bar"
                style={{ width: `${mintProgress}%` }}
              />
              <span className="progress-text">
                {formatProgress()}
              </span>
            </div>
            <div className="input-group">
              <input
                type="number"
                value={mintAmount}
                onChange={(e) => setMintAmount(e.target.value)}
                placeholder="Enter SOL amount (Min 0.01)"
                className="token-input"
                disabled={token.isMintEnded || isProcessing}
              />
              <button
                className="action-button mint-button"
                onClick={handleMint}
                disabled={token.isMintEnded || isProcessing || Date.now() < MINT_START_TIME}
              >
                <span className="button-icon">
                  {isProcessing ? '⌛' :
                    Date.now() < MINT_START_TIME ? '⏳' :
                      token.isMintEnded ? '✓' : '🌟'}
                </span>
                {isProcessing ? 'Processing...' :
                  Date.now() < MINT_START_TIME ? 'Not Started' :
                    token.isMintEnded ? 'Mint Ended' : 'Mint Now'}
              </button>
            </div>
          </div>

          <div className="operation-section burn-section">
            <h3>Burn {token.name}</h3>
            <p className="burn-description">Burn {token.name} tokens to redeem SOL</p>
            <div className="input-group">
              <input
                type="number"
                value={burnAmount}
                onChange={(e) => setBurnAmount(e.target.value)}
                placeholder="Enter token amount to burn (Min 10000)"
                className="token-input"
                disabled={isProcessing || (token.isMintEnded && token.tokenAddress !== "GXBQgQijLFgfNgHazpr7KbwK2W7A8zdSTWk3xQCymq56")}
              />
              <button
                className="action-button burn-button"
                onClick={handleBurn}
                disabled={isProcessing || (token.isMintEnded && token.tokenAddress !== "GXBQgQijLFgfNgHazpr7KbwK2W7A8zdSTWk3xQCymq56")}
              >
                <span className="button-icon">
                  {isProcessing ? '⌛' : '🔥'}
                </span>
                {isProcessing ? 'Processing...' : 'Burn Now'}
              </button>
            </div>
          </div>
        </div>

        <div className="token-description-card">
          <h3>{tokenDescription?.title || `About ${token.name}`}</h3>
          <p className="token-description">
            {tokenDescription?.description || token.description}
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default TokenDetail;