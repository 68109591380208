const RPC_URL = "https://hardworking-wispy-isle.solana-mainnet.quiknode.pro/44477c767669d2d312cdc2802c198ed71bad3c7e/";

interface TokenInfo {
  holders: number;
  totalSupply: number;
  circulatingSupply: number;
}

const CACHE_DURATION = 5 * 60 * 1000;
const tokenInfoCache = new Map<string, { data: TokenInfo; timestamp: number }>();

export async function getTokenInfo(tokenAddress: string): Promise<TokenInfo> {
  const now = Date.now();
  const cached = tokenInfoCache.get(tokenAddress);
  if (cached && (now - cached.timestamp) < CACHE_DURATION) {
    return cached.data;
  }

  try {

    const holdersResponse = await fetch(RPC_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "jsonrpc": "2.0",
        "id": 1,
        "method": "getProgramAccounts",
        "params": [
          "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA",
          {
            "encoding": "jsonParsed",
            "filters": [
              { "dataSize": 165 },
              { "memcmp": { "offset": 0, "bytes": tokenAddress } }
            ]
          }
        ]
      })
    });

    const holdersData = await holdersResponse.json();
    const validHolders = holdersData.result.filter((account: any) => 
      account.account.data.parsed.info.tokenAmount.uiAmount > 0
    ).length;


    const supplyResponse = await fetch(RPC_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "jsonrpc": "2.0",
        "id": 1,
        "method": "getTokenSupply",
        "params": [tokenAddress]
      })
    });

    const supplyData = await supplyResponse.json();
    const totalSupply = supplyData.result.value.uiAmount;

    const tokenInfo = {
      holders: validHolders,
      totalSupply: totalSupply,
      circulatingSupply: totalSupply 
    };

    tokenInfoCache.set(tokenAddress, {
      data: tokenInfo,
      timestamp: now
    });

    return tokenInfo;
  } catch (error) {
    console.error('Error fetching token info:', error);
    return { holders: 0, totalSupply: 0, circulatingSupply: 0 };
  }
}