import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import TokenList from '../components/TokenList';

const Home = () => {
  const navigate = useNavigate();

  return (
    <div style={{ minHeight: '100vh' }}>
      <Navbar />
      <div className="hero-container">
        <h1 className="hero-title">
        The Assure Protocol Token Launch Platform
        </h1>
        <button 
          className="create-token-btn" 
          onClick={() => navigate('/create-token')}
        >
          Create Token
        </button>
      </div>
      <div style={{ marginTop: '2rem' }}>
        <TokenList />
      </div>
    </div>
  );
};

export default Home;