import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import solanaLogo from '../assets/favicon.png';


const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { connected, publicKey } = useWallet();
  const navigate = useNavigate();

  const getShortAddress = (address: string) => {
    return `${address.slice(0, 0)}${address.slice(-4)}`;
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-brand">
          <img
            src={solanaLogo}
            alt="Solana Logo"
            className="navbar-logo"
            onClick={() => navigate('/')}
            style={{ cursor: 'pointer' }}
          />
        </div>

        <div className="navbar-social">
          <a href="https://x.com/SOMANSOLANA" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://t.me/SOMANSOL" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-telegram"></i>
          </a>
        </div>

        <div className="navbar-buttons">
          <button className="how-it-works-btn" onClick={() => setIsModalOpen(true)}>
            How it works?
          </button>
          <WalletMultiButton
            className="wallet-adapter-button-trigger"
          >
            {connected && publicKey ? getShortAddress(publicKey.toString()) : 'Connect'}
          </WalletMultiButton>
        </div>
      </nav>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="modal-close" onClick={() => setIsModalOpen(false)}>
              ×
            </button>
            <h2>How it works?</h2>
            <div className="modal-text">
              <p><strong>Soman</strong> - is a brand-new Assure Protocol token deployment and distribution platform on the Solana network. Protecting early investors is the core belief of the Assure Protocol.</p>

              <h3>For Investors</h3>
              <p>During the minting phase, you can invest in any token you like. At any time before the minting phase ends, you may BURN the tokens you've minted to retrieve 100% of the SOL you invested.</p>
              <p>Once trading begins, you can still BURN the minted tokens but will only be able to retrieve 50% of the SOL you invested.</p>
              <p>Assure Protocol prioritizes protecting investors' rights to the greatest extent, reducing the risk of malicious actions by developers.</p>
              <p>After the minting is completed, 48% of the tokens and SOL will be added as liquidity to Raydium, and the LP tokens will be burned. 50% of the funds will be retained in the Assure Protocol contract and can only be withdrawn by burning the tokens, which will protect investors.</p>

              <h3>For Dev:</h3>
              <p>After the token is created, you can mint tokens yourself. After the minting ends, you can receive 0.5% to 1% of the development revenue. The platform's trading fee of 30% will be distributed to the developers.</p>

              <div className="docs-link">
                <a
                  href="https://soman-fun.gitbook.io/soman.fun-docs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read Docs
                </a>
              </div>
            </div>
            <button className="modal-ok" onClick={() => setIsModalOpen(false)}>
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;