import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import PuntIcon from '../assets/psoman.png';
import PuntsIcon from '../assets/Punts.png';
import GotaIcon from '../assets/gota.png';
import ShrubIcon from '../assets/shrub.png';
import PopcatIcon from '../assets/popcat.png';
import LuceIcon from '../assets/Luce.png';
import { getTokenPriceWithCache, formatPrice, formatVolume } from '../services/tokenPrice';
import { getTokenInfo } from '../services/tokenService';

interface TokenData {
  name: string;
  creator: string;
  creatorAvatar: string;
  tokenImage: string;
  tokenAddress: string;
  price: string;
  price24h: string;
  marketCap: string;
  volume: string;
  holders: string;
  circulatingSupply: string;
  transactions: number;
  change: string;
  maxSupply: string;
  currentSupply: string;
  isMintEnded: boolean;
}

type CreatorAvatarColors = {
  [key: string]: string;
};

type FilterType = 'all' | 'minting' | 'ended';

const TokenList = () => {
  const navigate = useNavigate();
  // @ts-ignore
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterType, setFilterType] = useState<FilterType>('all');

  const colors: CreatorAvatarColors = {
    '🟢': '#14F195',
    '🟡': '#FFB800',
    '🔴': '#FF4B4B'
  };

  const initialTokens: TokenData[] = [
    {
      name: "PSOMAN",
      creator: "SOMAN",
      creatorAvatar: "🟢",
      tokenImage: PuntIcon,
      tokenAddress: "GXBQgQijLFgfNgHazpr7KbwK2W7A8zdSTWk3xQCymq56",
      price: "$0.00",
      price24h: "$0.00",
      marketCap: "$0",
      transactions: 689,
      change: "0%",
      volume: "$0",
      holders: "0",
      circulatingSupply: "750,000,000",
      maxSupply: "1,000,000,000",
      currentSupply: "750,000,000",
      isMintEnded: false
    },
    {
      name: "PUNT",
      creator: "EMhsQ",
      creatorAvatar: "🔴",
      tokenImage: PuntsIcon,
      tokenAddress: "2qEHjDLDLbuBgRYvsxhc5D6uDWAivNFZGan56P1tpump",
      price: "$0.00",
      price24h: "$0.00",
      marketCap: "$0",
      transactions: 13,
      change: "0%",
      volume: "$0",
      holders: "0",
      circulatingSupply: "999,858,795.88",
      maxSupply: "999,858,795.88",
      currentSupply: "999,858,795.88",
      isMintEnded: true
    },
    {
      name: "GOTA",
      creator: "GotaLabs",
      creatorAvatar: "🔴",
      tokenImage: GotaIcon,
      tokenAddress: "CzLSujWBLFsSjncfkh59rUFqvafWcY5tzedWJSuypump",
      price: "$0.00",
      price24h: "$0.00",
      marketCap: "$0",
      transactions: 156,
      change: "0%",
      volume: "$0",
      holders: "0",
      circulatingSupply: "999,998,266.59",
      maxSupply: "999,998,266.59",
      currentSupply: "999,998,266.59",
      isMintEnded: true
    },
    {
      name: "SHRUB",
      creator: "ShrubFinance",
      creatorAvatar: "🔴",
      tokenImage: ShrubIcon,
      tokenAddress: "GN78Djb7J2xc2ZLZaJLoGoF8FU6x7DnK3kUJFsnDpump",
      price: "$0.00",
      price24h: "$0.00",
      marketCap: "$0",
      transactions: 89,
      change: "0%",
      volume: "$0",
      holders: "0",
      circulatingSupply: "999,998,266.59",
      maxSupply: "999,998,266.59",
      currentSupply: "999,998,266.59",
      isMintEnded: true
    },
    {
      name: "POPCAT",
      creator: "EO0Y",
      creatorAvatar: "🔴",
      tokenImage: PopcatIcon,
      tokenAddress: "7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr",
      price: "$0.00",
      price24h: "$0.00",
      marketCap: "$0",
      transactions: 245,
      change: "0%",
      volume: "$0",
      holders: "0",
      circulatingSupply: "979,943,720.55",
      maxSupply: "979,943,720.55",
      currentSupply: "979,943,720.55",
      isMintEnded: true
    },
    {
      name: "LUCE",
      creator: "9v7am",
      creatorAvatar: "🔴",
      tokenImage: LuceIcon,
      tokenAddress: "CBdCxKo9QavR9hfShgpEBG3zekorAeD7W1jfq2o3pump",
      price: "$0.00",
      price24h: "$0.00",
      marketCap: "$0",
      transactions: 1,
      change: "0%",
      volume: "$0",
      holders: "0",
      circulatingSupply: "979,943,720.55",
      maxSupply: "979,943,720.55",
      currentSupply: "979,943,720.55",
      isMintEnded: true
    }
  ];

  const [tokens, setTokens] = useState<TokenData[]>(initialTokens);

  const getCreatorStatusColor = (avatar: string): string => {
    return colors[avatar] || '#14F195';
  };

  const getFilteredTokens = () => {
    if (filterType === 'all') {
      return tokens;
    }
    return tokens.filter(token => {
      const currentSupply = parseFloat(token.currentSupply.replace(/,/g, ''));
      const maxSupply = parseFloat(token.maxSupply.replace(/,/g, ''));
      return filterType === 'minting' ? currentSupply < maxSupply : currentSupply >= maxSupply;
    });
  };

const updateTokenData = async () => {
  try {
    const updatedTokens = await Promise.all(
      tokens.map(async (token) => {
        try {
          const priceData = await getTokenPriceWithCache(token.tokenAddress);
          const tokenInfo = await getTokenInfo(token.tokenAddress);
          const currentPrice = priceData?.value || 0;
          const change24h = priceData?.change24h || 0;
          const volume24h = priceData?.volume24h || 0;

          const circulatingSupply = parseFloat(token.circulatingSupply.replace(/,/g, ''));
          const marketCap = currentPrice * circulatingSupply;

          const currentSupplyNum = parseFloat(token.currentSupply.replace(/,/g, ''));
          const maxSupplyNum = parseFloat(token.maxSupply.replace(/,/g, ''));

          return {
            ...token,
            price: formatPrice(currentPrice),
            marketCap: formatPrice(marketCap),
            holders: tokenInfo.holders.toLocaleString(),
            volume: formatVolume(volume24h),
            change: change24h >= 0 ? `+${change24h.toFixed(2)}%` : `${change24h.toFixed(2)}%`,
            isMintEnded: currentSupplyNum >= maxSupplyNum
          };
        } catch (err) {
          console.error(`Error updating token ${token.tokenAddress}:`, err);
          return token; 
        }
      })
    );

    setTokens(updatedTokens);
    setError(null);
  } catch (err) {
    console.error('Error updating data:', err);

  }
};


useEffect(() => {
  const initialLoad = async () => {
    await updateTokenData();
    setIsInitialLoading(false);
  };
  initialLoad();
}, []);


useEffect(() => {
  const interval = setInterval(updateTokenData, 60000); 
  return () => clearInterval(interval);
}, []);

return (
  <div className="token-list-container">
    <div className="token-list-header">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search assets or address"
          className="search-input"
        />
        <button className="search-button">Search</button>
      </div>
      <div className="token-filters">
        <button
          className={`filter-btn ${filterType === 'all' ? 'active' : ''}`}
          onClick={() => setFilterType('all')}
        >
          <i className="fas fa-list"></i>
          <span>All</span>
        </button>
        <button
          className={`filter-btn ${filterType === 'minting' ? 'active' : ''}`}
          onClick={() => setFilterType('minting')}
        >
          <i className="fas fa-fire"></i>
          <span>Minting</span>
        </button>
        <button
          className={`filter-btn ${filterType === 'ended' ? 'active' : ''}`}
          onClick={() => setFilterType('ended')}
        >
          <i className="fas fa-check-circle"></i>
          <span>Mint Ended</span>
        </button>
      </div>
    </div>

    {error && (
      <div className="error-message">
        {error}
      </div>
    )}

    <div className="token-cards">
      {getFilteredTokens().map(token => (
        <motion.div
          key={token.tokenAddress}
          className="token-card"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{ y: -5 }}
          transition={{ duration: 0.3 }}
          onClick={() => navigate(`/token/${token.tokenAddress}`)}
          style={{ cursor: 'pointer' }}
        >
          <div className="token-card-header">
            <img
              src={token.tokenImage}
              alt={token.name}
              className="token-image"
            />
            <div className="token-info">
              <h3>{token.name}</h3>
              <div className="creator-info">
                <span className="creator-label">Created by</span>
                <span
                  className="creator-status"
                  style={{
                    backgroundColor: getCreatorStatusColor(token.creatorAvatar),
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '6px'
                  }}
                />
                <span className="creator-name">{token.creator}</span>
              </div>
            </div>
          </div>

          <div className="token-stats">
            <div className="stat-item">
              <span className="stat-label">Price</span>
              <span className="stat-value price">
                {token.price}
              </span>
            </div>

            <div className="stat-item">
              <span className="stat-label">Market Cap</span>
              <span className="stat-value">
                {token.marketCap}
              </span>
            </div>

            <div className="stat-item">
              <span className="stat-label">24h</span>
              <span className={`stat-value ${token.change.includes('+') ? 'change-positive' : 'change-negative'}`}>
                {token.change}
              </span>
            </div>

            <div className="stat-item">
              <span className="stat-label">Holders</span>
              <span className="stat-value">
                {token.holders}
              </span>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  </div>
);
};

export default TokenList;