// Constants
const BIRDEYE_BASE_URL = 'https://public-api.birdeye.so';
const BIRDEYE_API_KEY = 'b11b93cc566e4d3db46f564530823cc5';
const SOLSCAN_BASE_URL = 'https://public-api.solscan.io/v2';
const SOLSCAN_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE3MzEwNzc5MTIwMDMsImVtYWlsIjoicHloemhoZGVhbmVldUBnbWFpbC5jb20iLCJhY3Rpb24iOiJ0b2tlbi1hcGkiLCJhcGlWZXJzaW9uIjoidjIiLCJpYXQiOjE3MzEwNzc5MTJ9.E2-C-ayc5N3QqhqghrwD5UQKUHyOKGKvrUFIeKYjPEA';

const PRICE_CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const HOLDERS_CACHE_DURATION = 10 * 60 * 1000; // 10 minutes
const MAX_RETRIES = 3;

// Types
export interface TokenPriceData {
  value: number;
  change24h: number;
  volume24h: number;
  updateUnixTime: number;
}

export interface TokenHoldersData {
  holder: number;
  updateTime: number;
}

// Cache implementations
const priceCache = new Map<string, { data: TokenPriceData; timestamp: number }>();
const holdersCache = new Map<string, { data: TokenHoldersData; timestamp: number }>();

// API functions
export const getTokenPrice = async (tokenAddress: string): Promise<TokenPriceData> => {
  let retries = 0;
  
  while (retries < MAX_RETRIES) {
    try {
      const response = await fetch(
        `${BIRDEYE_BASE_URL}/defi/price?address=${tokenAddress}`,
        {
          headers: { 'X-API-KEY': BIRDEYE_API_KEY }
        }
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Raw price data for', tokenAddress, ':', data);
      return data.data;
    } catch (error) {
      console.error(`Attempt ${retries + 1} failed for price:`, error);
      retries++;
      
      if (retries === MAX_RETRIES) {
        console.error('Max retries reached for token:', tokenAddress);
        return {
          value: 0,
          change24h: 0,
          volume24h: 0,
          updateUnixTime: Date.now()
        };
      }
      
      await new Promise(resolve => setTimeout(resolve, 1000 * retries));
    }
  }

  return {
    value: 0,
    change24h: 0,
    volume24h: 0,
    updateUnixTime: Date.now()
  };
};

export const getTokenHolders = async (tokenAddress: string): Promise<TokenHoldersData> => {
  let retries = 0;
  
  while (retries < MAX_RETRIES) {
    try {
      const response = await fetch(
        `${SOLSCAN_BASE_URL}/token/holders?tokenAddress=${tokenAddress}`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${SOLSCAN_API_KEY}`
          }
        }
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Raw holders data from Solscan for', tokenAddress, ':', data);
      
      return {
        holder: parseInt(data?.data?.total?.toString() || '0'),
        updateTime: Date.now()
      };
    } catch (error) {
      console.error(`Attempt ${retries + 1} failed for holders:`, error);
      retries++;
      
      if (retries === MAX_RETRIES) {
        return { holder: 0, updateTime: Date.now() };
      }
      
      await new Promise(resolve => setTimeout(resolve, 2000 * retries));
    }
  }

  return { holder: 0, updateTime: Date.now() };
};

// Cache wrapper functions
export const getTokenPriceWithCache = async (tokenAddress: string): Promise<TokenPriceData> => {
  const now = Date.now();
  const cached = priceCache.get(tokenAddress);

  if (cached && (now - cached.timestamp) < PRICE_CACHE_DURATION) {
    return cached.data;
  }

  const data = await getTokenPrice(tokenAddress);
  priceCache.set(tokenAddress, { data, timestamp: now });
  return data;
};

export const getTokenHoldersWithCache = async (tokenAddress: string): Promise<TokenHoldersData> => {
  const now = Date.now();
  const cached = holdersCache.get(tokenAddress);

  if (cached && (now - cached.timestamp) < HOLDERS_CACHE_DURATION) {
    console.log('Using cached holders data for', tokenAddress);
    return cached.data;
  }

  try {
    const data = await getTokenHolders(tokenAddress);
    if (data.holder > 0) {
      holdersCache.set(tokenAddress, { data, timestamp: now });
      console.log('Cached new holders data for', tokenAddress, data);
    }
    return data;
  } catch (error) {
    console.error('Error in getTokenHoldersWithCache:', error);
    return cached?.data || { holder: 0, updateTime: now };
  }
};

// Formatting functions
export const formatPrice = (price: number): string => {
  if (!price || isNaN(price)) return '$0.00';

  if (price < 0.000001) return `$${price.toExponential(4)}`;
  if (price < 0.01) return `$${price.toFixed(6)}`;
  if (price < 1) return `$${price.toFixed(4)}`;
  if (price >= 1000000) return `$${(price / 1000000).toFixed(2)}M`;
  if (price >= 1000) return `$${(price / 1000).toFixed(2)}K`;
  
  return `$${price.toFixed(2)}`;
};

export const formatVolume = (volume: number): string => {
  if (!volume || isNaN(volume)) return '$0';

  if (volume >= 1000000) return `$${(volume / 1000000).toFixed(1)}M`;
  if (volume >= 1000) return `$${(volume / 1000).toFixed(1)}K`;
  
  return `$${volume.toFixed(1)}`;
};

export const formatChange = (change: number): string => {
  if (!change || isNaN(change)) return '0.00%';
  const sign = change >= 0 ? '+' : '';
  return `${sign}${change.toFixed(2)}%`;
};

export const formatHolders = (holders: number): string => {
  if (!holders) return '0';
  
  if (holders >= 1000000) return `${(holders / 1000000).toFixed(1)}M`;
  if (holders >= 1000) return `${(holders / 1000).toFixed(1)}K`;
  
  return holders.toLocaleString();
};