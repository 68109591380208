// src/polyfills.ts
import { Buffer } from 'buffer';
import process from 'process';

if (typeof window !== 'undefined') {
  window.global = window;
  window.Buffer = Buffer;
  window.process = process as NodeJS.Process; 
  globalThis.Buffer = Buffer;
}

declare global {
  interface Window {
    global: typeof globalThis;
    Buffer: typeof Buffer;
    process: NodeJS.Process; 
  }
}