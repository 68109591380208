import Navbar from '../components/Navbar';
const CreateToken = () => {
  return (
    <>
      <Navbar />
      <div className="create-token-page">
        <div className="create-token-container">
          <h2>Deploy your<br />Assure Protocol<br />tokens.</h2>
          <div className="features-list">
            <div className="feature-item">
              <span className="check-icon">✓</span>
              <span>A new way to launch fair tokens</span>
            </div>
            <div className="feature-item">
              <span className="check-icon">✓</span>
              <span>Early Trader Protection</span>
            </div>
            <div className="feature-item">
              <span className="check-icon">✓</span>
              <span>More traders participate</span>
            </div>
          </div>

          <div className="action-section">
            <div className="status-box">
              <h3>Self-service token creation will be launched this week.</h3>
              <p>Create the latest Assure Protocol tokens.</p>
              <button className="create-nft-btn">Create Token</button>
            </div>

            <div className="status-box disabled">
              <h3>Tokens have been deployed and transactions are start</h3>
              <p>Deploy liquidity to activate the SWAP trading mode</p>
              <button className="coming-soon-btn" disabled>Coming soon</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateToken;